import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
import CareerComponent from '../../../components/career/CareerComponent';


function CareerTemplate(props) {

    const careerData = props.cmsData?.career?.edges[0]?.node?.frontmatter ?? {}

    return (
        <div>
            <CareerComponent cmsData={careerData} />
        </div>
    )
}

export default function Career() {
    return (
      <StaticQuery
        query={graphql`
          query {
            career: allMarkdownRemark(filter: 
              {frontmatter: {templateKey: {eq: "en-career"}}}) {
              edges {
                node {
                  frontmatter {
                    bg {
                        childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    title
                    seo {
                      title
                      descr
                    }
                    textBlocks {
                        text
                    }
                    quoteSection {
                        title
                        text
                        personImage {
                            childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                        personName
                        role
                    }
                    servicesTitle
                    servicesBg {
                        childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    servicesSection {
                        title
                        text
                        path
                    }
                    endSection {
                        title
                        icon {
                            childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                        buttonText
                        buttonPath
                    }
                  }
                }
              }
            },
          }
        `}
        render={(data) => <CareerTemplate cmsData={data} />}
      />
    );
  }
  